import React from 'react'
import { Link } from 'gatsby'
import ReactWOW from 'react-wow'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'

const Salaries = () => {
    return (
        <section className="solutions-area ptb-70 bg-wspk">
            <div className="container">
                <div className="row align-items-center pb-30">
                    <div className="col-lg-12 col-md-12 pt-70 pb-30 center">
                        <h2 className="subtitle">Vous êtes salarié, collaborateur</h2>
                    </div>

                        <div className="col-lg-6 col-md-6">
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box section-title pt-50">
                                    <p>Accédez à votre compte pour déposer un signalement, suivre le traitement, échanger avec le référent en mode anonyme ou confidentiel.</p>
                                    <Link to="https://agir.wispeek.com" className="default-btn">
                                        <i className="flaticon-right"></i> 
                                        Espace salarié <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box section-title pt-50">
                                    <p>Vous ne souhaitez pas créer de compte. Ce formulaire sera chiffré et envoyé au référent. Votre identité vous sera demandé.</p>
                                    <Link to="https://wspk.me/to/wde3ikLTS5XoiqZEY" target="_blank" className="default-btn" style={{backgroundColor:'coral'}}>
                                        <i className="flaticon-right"></i> 
                                        Déclarer un signalement <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                <div className="faq-accordion pt-50 pb-70">
                    <Accordion allowZeroExpanded>
                            
                        <AccordionItem uuid="a">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q1. Pourquoi se créer un compte ?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                            <p><span className='bold'>La création du compte est anonyme :</span> votre adresse mail et votre mot de passe ne sont pas enregistrés et communiqués. Le code entreprise vous est fourni par votre organisation.</p>
                            <p className='bold'>Créez votre compte</p>
                            <p>Cette interface est personnelle, seul vous pouvez y accéder. Faites attention de bien retenir votre mot de passe et de ne le divulguer à personne.</p>

                            <p>Votre compte vous permet de :
                                <ul>
                                    <li>Faire des signalements et des remontées d’information en mode confidentiel ou anonyme</li>
                                    <li>Discuter via une messagerie sécurisée avec le référent en charge de votre dossier et d’échanger tous types de documents</li>
                                    <li>Consulter les documents mis à votre disposition (charte de l’entreprise, procédures, formulaires, etc.).</li>
                                    <li>Conservez bien votre mot de passe, comme les données sont chiffrées, nous ne pouvons pas le renouveller.</li>
                                </ul>
                            </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                            
                        <AccordionItem uuid="b">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q2. Engagement Protection et Responsabilité ?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                            <p>Wispeek est une plateforme de signalement interne conforme aux obligations légales de protection des lanceurs d’alerte prévues par les lois Sapin 2, Waserman ou encore le Devoir de Vigilance.</p>
                            <p><span className='bold'>Définition :</span> un lanceur d'alerte est une personne physique qui signale ou divulgue, sans contrepartie financière directe et de bonne foi, des informations portant sur un crime, un délit, une menace ou un préjudice pour l'intérêt général, une violation ou une tentative de dissimulation d'une violation d'un engagement international régulièrement ratifié ou approuvé par la France, d'un acte unilatéral d'une organisation internationale pris sur le fondement d'un tel engagement, du droit de l'Union européenne, de la loi ou du règlement. Lorsque les informations n'ont pas été obtenues dans le cadre des activités professionnelles mentionnées au I de l'article 8, le lanceur d'alerte doit en avoir eu personnellement connaissance.</p>
                            <p><span className='bold'>Procédure :</span> Dès l’envoi de votre signalement, en mode confidentiel ou anonyme, vous recevez automatiquement un récépissé. Votre entreprise doit ensuite vous répondre par écrit, via la messagerie sécurisée, dans un délai de 3 mois pour vous informer sur le suivi de votre signalement.</p>
                            <p><span className='bold'>Protection des lanceurs d’alerte :</span> Wispeek garantit la confidentialité de votre identité ainsi que celles des personnes mentionnées et des informations que vous serez amené à révéler.</p>
                            <p className='pt-30'>Nous vous encourageons à révéler votre identité afin de fluidifier le processus et garantir votre protection. Toutefois, si vous souhaitez rester anonyme, Wispeek garantit votre anonymat.</p>
                            <p>Afin de lutter contre les risques de représailles, vos échanges sont chiffrés et seul le correspondant peut les consulter. Lors d’un signalement recevable, le lanceur d’alerte est protégé contre les mesures de représailles disciplinaires internes et les condamnations civiles ou pénales.</p>
                            <p><span className='bold'>Alerte abusive et diffamation :</span> Nous vous rappelons que les informations que vous donnez sur vous-même, vos collègues ou tout aspect des activités de l'entreprise peuvent affecter des tiers. Veuillez noter à cet égard qu’une alerte faite de mauvaise foi peut donner lieu à des sanctions disciplinaires et des poursuites judiciaires.</p>
                            <p>Votre organisation a choisi de mettre en place un dispositif d’alertes professionnelles qui respecte les normes de protections des données personnelles établies par le <span className='bold'>RGPD</span> et la <span className='bold'>CNIL</span>.</p>
                            <p>Vous pouvez exercer les droits qui vous sont accordés par la réglementation, pour ce faire, veuillez consulter votre société pour en connaître les modalités.</p>
                            <p>Toute personne dont les données personnelles sont collectées et traitées dans le cadre du dispositif d’alerte dispose notamment du droit de s’opposer au traitement de ses données personnelles pour des raisons tenant à sa situation particulière. Nous attirons néanmoins votre attention sur le fait que ce droit d’opposition ne peut être exercé pour empêcher notre société de remplir ses obligations légales en matière de traitement des signalements et de protection de l’auteur d’un signalement.</p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem uuid="c">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q3. La charte éthique de mon entreprise
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div>
                                    <h3>1. RESPECT DES LOIS, NORMES ET RÈGLEMENTATIONS</h3>
                                    <p>Notre charte éthique est la manifestation de notre engagement au quotidien et gouverne nos actions. Elle s’adresse à tous nos collaborateurs ainsi qu’à nos clients et nos fournisseurs. Nous nous engageons à mener notre activité avec éthique et d’une manière responsable.</p>
                                    <p>DAMMANN Frères s’engage à agir en accord avec les lois et règlementations internationales, nationales, locales.</p>
                                    <p>Nous nous engageons notamment à respecter :</p>
                                    <ul>
                                        <li>Les principes de la Déclaration Universelle des Droits de l’Homme du 10 décembre 1948.</li>
                                        <li>Les principes directeurs des Nations Unies relatifs aux entreprises et aux droits de l’Homme en date du 16 juin 2011.</li>
                                        <li>Les normes fondamentales de l’Organisation Internationale du Travail.</li>
                                        <li>Les principes du Syndicat français du Thé et des Plantes à Infusion.</li>
                                        <li>Les principes de United Nations Global Compact.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>2. DROITS DE L’HOMME ET ENGAGEMENTS SOCIAUX</h3>
                                    <p>Les droits de l’Homme, l’interdiction du travail des enfants, du travail forcé, le respect de la liberté d’association sont une priorité pour DAMMANN Frères.</p>
                                    <p>Nos salariés travaillent en conformité avec les lois applicables. Le travail doit être effectué volontairement et les employés doivent être traités avec dignité et respect.</p>           
                                    <p>Nous veillons à ce que les principes de non-discrimination et les droits individuels soient respectés et que les initiatives prises contribuent à éliminer toute discrimination fondée sur la race, la couleur de peau, le saxe, l’orientation sexuelle, le handicap, le statut matrimonial, l’âge, la religion, les opinions politiques, la nationalité, l’appartenance ethnique ou l’origine sociale.</p>
                                    <p>Nous nous engageons à protéger nos collaborateurs de toutes ces sortes de discriminations, aussi bien à l’embauche que pendant l’évolution au sein de DAMMANN Frères.</p>
                                    <p>Les conditions d’embauche et les évolutions sont fondées sur les compétences, le mérite et la performance. Ainsi DAMMANN Frères s’engage à l’égalité des salaires Hommes/Femme. Par ailleurs l’entreprise favorise les embauches dans son bassin d’emploi local.</p>            
                                    <p>Nous prenons soin d’informer nos salariés des risques liés à la santé et à la sécurité au travail.</p>
                                    <p>Nos salariés travaillent dans des conditions et lieux sécurisés et sains conformément aux lois applicables.</p>
                                    <p>Nous aspirons à assurer l’épanouissement personnel de nos collaborateurs, leur bien-être, à promouvoir la confiance mutuelle entreprise-salarié, et faciliter l’accès aux formations et en développant leurs compétences, tout en assurant l’égalité des chances dans le parcours professionnel.</p>
                                    <p>Nous sensibilisons nos salariés sur les risques des comportements déviants et dangereux, tels que le harcèlement sexuel ou moral. Le harcèlement est strictement interdit. Nous veillons à ce qu’aucun de nos employés ne soit soumis à des contraintes physiques ou psychologiques.</p>
                                    <p>Ils sont tenus de se comporter en tout temps envers DAMMANN Frères d’une manière loyale qui protège les intérêts et la réputation de l’entreprise. Ils doivent agir avec intégrité car ils constituent une partie de l’image de l’entreprise, ils doivent encourager la réussite collective de DAMMANN Frères en favorisant une bonne ambiance au travail, respecter le patrimoine matériel et immatériel de DAMMANN Frères, respecter les collaborateurs avec lesquels ils sont en relation et favoriser le dialogue pour résoudre d’éventuels conflits, prendre part active à la politique d’amélioration progressive et continue pour tout ce qui concerne la qualité, l’hygiène, la sécurité, l’environnement, et le développement durable.</p>
                                </div>
                                <div>
                                    <h3>3. SOUCI PERMANENT DE LA QUALITÉ ET DU DEVELOPPEMENT DURABLE</h3>
                                    <p>Dans le but de respecter les principes du développement durable, nous mettons en place des actions pour avoir un impact positif sur la société, un business model pérenne qui apporte de la valeur à chaque partie prenante et un impact faible sur l’environnement. DAMMANN Frères renouvelle chaque année son engagement au programme United Nations Global Compact.</p>
                                    <p>Nos engagements passent par un maintien rigoureux de la qualité de nos produits et services, la garantie sanitaire de nos produits. L’entreprise est certifiée IFS depuis 2013 et suit un plan de contrôle strict et plus rigoureux que la règlementation afin d’assurer un produit sûr et sain aux consommateurs et qui tende vers l’absence de détections de pesticides.</p>
                                    <p>Sur son site de production, DAMMANN Frères veille à garantir des procédés industriels respectueux de la nature et de son environnement.</p>
                                    <p>Dans les multiples régions du monde où elle exerce ses activités, DAMMANN Frères est résolue à protéger et à améliorer la qualité de l’environnement en misant sur des pratiques d’exploitations agricoles responsables, vertueuses et respectueuse dans ce domaine.</p>
                                    <p>DAMMANN Frères recommande à ses clients des pratiques visant à optimiser les moyens de transports pour un objectif de réduction de l’empreinte carbone.</p>
                                    <p>L’entreprise encourage ses partenaires à soutenir les projets visant l’amélioration de l’environnement, tant au travail que dans leur collectivité.</p>
                                </div>
                                <div>
                                    <h3>4. PRÉVENTION ET DÉTECTION DE LA CORRUPTION</h3>
                                    <p>Nous interdisons tous les comportements qui pourraient être définis comme les actes de corruption actifs qui impliquent des propositions ou des cadeaux effectués directement ou non à une personne publique ou privée y compris offres, promesses, avantages, pots de vin, afin que cette personne accomplisse ou s’abstienne d’accomplir une action directement ou indirectement liée à sa mission.</p>
                                    <p>Nos employés et partenaires commerciaux sont tenus de respecter les lois anti-corruption qui leur sont applicables et de dénoncer toute tentative ou extorsion de fonds.</p>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>

                    </Accordion>         
                </div>
            </div>
        </section>
    )
}

export default Salaries;